<template>
	<div class="page">
		<h3>Task (Single Action)</h3>
		<div v-if="loading">
			<h4>Loading...</h4>
		</div>
		<div class="designer designer-action">
			<TextInput label="Name" v-model="entity.name" />

			<template v-if="entity">
				<div v-for="(unit,i) in entity.units" :key="i">
					{{unitData[unit.type][unit.key].name}}

					<TextInput label="Value" type="number" v-model="unit.value" />
					<button @click="() => (entity.units.splice(i,1))"><i class="fal fa-fw fa-trash"></i></button>
				</div>
			</template>
		</div>

		<div class="action-bar">
			<router-link to="/board">
				<button>
					<i class="fal fa-fw fa-angle-left"></i>
				</button>
			</router-link>
			<button @click="() => (addMenu = true)">
				<i class="fal fa-fw fa-plus"></i>
			</button>
			<button @click.prevent="() => save(entity)">
				<i class="fal fa-fw fa-save"></i>
			</button>
		</div>

		<SideMenu :open="addMenu" @close="() => (addMenu = false)">
			<h4>Add Unit</h4>
			<h5>Time</h5>
			<ul>
				<li><a href="#" @click.prevent="addUnit({ type: 'time', key: 's' })">Seconds</a></li>
				<li><a href="#" @click.prevent="addUnit({ type: 'time', key: 'm' })">Minutes</a></li>
				<li><a href="#" @click.prevent="addUnit({ type: 'time', key: 'h' })">Hours</a></li>
			</ul>
			<h5>Weight</h5>
			<ul>
				<li><a href="#" @click.prevent="addUnit({ type: 'weight', key: 'g' })">Grams</a></li>
				<li><a href="#" @click.prevent="addUnit({ type: 'weight', key: 'kg' })">Kilo-grams</a></li>
				<li><a href="#" @click.prevent="addUnit({ type: 'weight', key: 'lb' })">Pounds</a></li>
			</ul>
			<h5>Liquid</h5>
			<ul>
				<li><a href="#" @click.prevent="addUnit({ type: 'liquid', key: 'ml' })">Millilitres</a></li>
				<li><a href="#" @click.prevent="addUnit({ type: 'liquid', key: 'l' })">Litres</a></li>
			</ul>
			<h5>Quantity</h5>
			<ul>
				<li><a href="#" @click.prevent="addUnit({ type: 'quantity', key: 'n' })">Repeats</a></li>
			</ul>
		</SideMenu>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "@/components/inputs/index";
import SideMenu from "@/components/menus/SideMenu";

const defaultEntity = JSON.stringify({
	type: "action",
	name: "",
	body: "",
	units: [],
	tags: [],
	prep_entityIds: [],
});

export default {
	name: "Action",
	props: {
		id: {},
	},
	components: {
		TextInput,
		SideMenu,
	},
	computed: {
		...mapGetters({
			d: "tasks/data",
			focused: "tasks/focused",
			unitData: "unitData",
		}),
	},
	data: () => ({
		loading: false,
		addMenu: false,
		entity: JSON.parse(defaultEntity)
	}),
	mounted() {
		this.load();
	},
	watch: {
		"$route.path"() {
			this.load();
		},
	},
	methods: {
		async load() {
			this.loading = true;
			this.entity = JSON.parse(defaultEntity);
			if (this.id) {
				await this.listEntities();
				const e = this.d.getFromId(this.id);
				this.entity = { ...this.entity, ...e };
			}
			this.loading = false;
		},
		...mapActions({
			listEntities: "tasks/list",
			createAction: "tasks/create",
			updateAction: "tasks/update",
			success: "broadcastSuccess"
		}),
		async save(entity) {
			this.loading = true;
			if (!this.id) {
				await this.createAction(entity);
				this.success("Created!");
				this.$router.push("/board");
			} else {
				await this.updateAction({ id: this.id, data: entity });
				this.success("Updated!");
			}
			this.loading = false;
		},
		addUnit({ type, key }) {
			this.entity.units.push({ type, key, value: 1 });
		}
	},
};
</script>
